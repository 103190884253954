import React, { useState, useRef, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { Outlet } from 'react-router-dom';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationBox from "./NotificationBox";
import SideBar3 from "./SideBar3";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import DeviceStatusButton from "./DeviceStatus";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { showCloseErrorToast, showCloseSuccessToast, showOpenErrorToast, showOpenSuccessToast } from '../utility/toastConfig.js';
import {
  Avatar,
  Paper,
  Grid,
  Dialog, DialogContent,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Link,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const schema = yup.object().shape({
  email: yup
  .string()
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    "Invalid email"
  )
  .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 digits")
    .required("Phone number is required"),
  password: yup
    .string()
    // .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(12, "Password must be at most 12 characters")
    .test('password-match', 'Both password fields must be filled to change password', function(value) {
      return (!!value && !!this.parent.confirmPassword) || (!value && !this.parent.confirmPassword);
    }),
  confirmPassword: yup
    .string()
    // .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .test('passwords-match', 'Passwords must match', function(value) {
      return this.parent.password === value;
    }),
  userId: yup.string().required("User Id is required"),
  fullName: yup.string().required("Full Name is required"),
  companyName: yup.string().required("Company Name is required"),
  // farmId: yup.string().required('Admin Id is required'),
  // farmName: yup.string().required('Address is required'),
  // assignedDevices: yup.string().required('Assigned devices is required'),
  // companyName: yup.string(),
  farmId: yup.string(),
  farmName: yup.string(),
  address: yup.string().required("Address is required"),
  assignedDevices: yup.string(),
});


const ScrollContainer = React.forwardRef(({ children }, ref) => (
  <Box
    ref={ref}
    sx={{
      display: "flex",
      overflow: "hidden", // Hide scrollbar
      width: "100%",
      padding: 0.7,
      position: "relative",
      margin: "0 20px", // Add horizontal margin to the container
    }}
  >
    {children}
  </Box>
));

const ArrowButton = ({ direction, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      [direction === "left" ? "left" : "right"]: 10,
      transform: "translateY(-50%)",
      backgroundColor: "#C4DAD0",
      color: "#000",
      "&:hover": { backgroundColor: "#B0BEB4" },
      zIndex: 1,
    }}
  >
    {direction === "left" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
  </IconButton>
);

export default function NavBar6() {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; 
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const manager_id = decodedToken.id;
  const [unreadAlertsCount, setUnreadAlertsCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const [fileError, setFileError] = useState(""); 
  const [open, setOpen] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialValues, setInitialValues] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    companyName: "",
    userId: "",
    assignedDevices: "",
    farmId: "",
    farmName: "",
    address: "",
  });
  const backgroundStyle = {
    backgroundColor: "#8FBAA6",
    backgroundSize: "cover",
    minHeight: "100vh",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
  };
  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        showCloseErrorToast("Image size is too large. Maximum allowed size is 5MB.")
        // setFileError("Image size is too large. Maximum allowed size is 5MB.");
        // setTimeout(() => {
        //   setFileError("");
        // }, 2000);
        setSelectedFile(null); 
      } else {
        setFileError(""); 
        setSelectedFile(file);
      }
    }
  };
  const handleDeleteClick = (setFieldValue) => {
    setSelectedFile(null);  
    setFieldValue("profile_picture", "/Images/profile_pic.jpg");  
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/api/user/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("user data fetched: ", response.data);
        const userData = response.data;
  
        let devicesResponse = null;
        try {
          devicesResponse = await axios.get(`/api/device-manager/manager/${userData.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log("device data: ", devicesResponse.data);
        } catch (deviceError) {
          if (deviceError.response && deviceError.response.status === 404) {
            console.warn("No devices found for this manager.");
          } else {
            console.error("Error fetching devices:", deviceError);
          }
          devicesResponse = { data: [] }; 
        }
  
        // Initialize empty sets for unique farm IDs and names
        const farmIdsSet = new Set();
        const farmNamesSet = new Set();
  
        devicesResponse.data.forEach((device) => {
          if (device && device.farm_id) {
            farmIdsSet.add(device.farm_id);
          }
          if (device && device.farm_name) {
            farmNamesSet.add(device.farm_name);
          }
        });
  
        // Convert sets to strings
        const assignedFarmIds = Array.from(farmIdsSet).join(", FID");
        const assignedFarmNames = Array.from(farmNamesSet).join(", ");
        console.log("assigned unique farm ids fetched: ", assignedFarmIds);
        console.log("assigned unique farm names fetched: ", assignedFarmNames);
  
        const assignedDevices = devicesResponse.data
          .filter(device => device && device.device_label) 
          .map(device => device.device_label)
          .join(", ");
        console.log("assigned devices fetched: ", assignedDevices);
  
        setInitialValues({
          profile_picture: userData.profile_picture || "",
          email: userData.email || "",
          phoneNumber: userData.phone_number || "",
          password: "",
          confirmPassword: "",
          fullName: userData.full_name || "",
          address: userData.address || "",
          companyName: userData.company || "",
          userId: userData.id || "",
          assignedDevices: assignedDevices || "",
          farmId: assignedFarmIds || "",
          farmName: assignedFarmNames || "",
        });
  
        console.log("Initial Values: ", initialValues);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  }, []);  
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const response = await axios.get(`/api/user/me`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log("user data fetched: ", response.data);
  //       const userData = response.data;
                
  //       const devicesResponse = await axios.get(`/api/device-manager/manager/${userData.id}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log("device data: ", devicesResponse.data);
        
  //       // Unique farm IDs and names
  //       const farmIdsSet = new Set();
  //       const farmNamesSet = new Set();
  //       devicesResponse.data.map(device => {
  //         if (device.farm_id) {
  //           farmIdsSet.add(device.farm_id);
  //         }
  //         if (device.farm_name) {
  //           farmNamesSet.add(device.farm_name);
  //         }
  //       });
  //       // Sets to strings
  //       const assignedFarmIds = Array.from(farmIdsSet).join(", FID");
  //       const assignedFarmNames = Array.from(farmNamesSet).join(", ");
  //       console.log("assigned unique farm ids fetched: ", assignedFarmIds);
  //       console.log("assigned unique farm names fetched: ", assignedFarmNames);
  
  //       const assignedDevices = devicesResponse.data.map(device => device.device_label).join(", ");
  //       console.log("assigned devices fetched: ", assignedDevices);
  //       // const assignedFarmIds = devicesResponse.data.map(device => device.farm_id).join(", ");
  //       // const assignedFarmNames = devicesResponse.data.map(device => device.farm_name).join(", ");
  //       // console.log("assigned farm ids fetched: ", assignedFarmIds);
  //       // console.log("assigned farm names fetched: ", assignedFarmNames);

  //       setInitialValues({
  //         email: userData.email || "",
  //         phoneNumber: userData.phone_number || "",
  //         password: "",
  //         confirmPassword: "",
  //         fullName: userData.full_name || "",
  //         address: userData.address || "",
  //         companyName: userData.company || "",
  //         userId: userData.id || "",
  //         assignedDevices: assignedDevices || "", 
  //         farmId: assignedFarmIds || "",
  //         farmName: assignedFarmNames || "",
  //       });
  //       console.log("Initial Values: ", initialValues);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchUserData();
  // }, []);

  const handleSubmitForm = async (values, { resetForm }) => {
    console.log("handleSubmitForm function triggered !!!");
    try {
      const allUsers = await axios.get(`/api/user/`);
      console.log("allUsers data fetched: ", allUsers.data);
      const allEmails = allUsers.data.map(user => user.email);
      console.log("allEmails: ", allEmails);
      const currentEmail = initialValues.email; 
      if (allEmails.includes(values.email) && values.email !== currentEmail) {
        showCloseErrorToast("This email is already registered. Please use a different email.")
        // setSuccessMessage("This email is already registered. Please use a different email.");
        // setTimeout(() => {
        //   setSuccessMessage("");
        // }, 2000);
        return; 
      }

      // const formData = {
      //   full_name: values.fullName,
      //   address: values.address,
      //   email: values.email,
      //   // password: values.password,
      //   phone_number: values.phoneNumber,
      //   user_role: "customer-manager",
      //   company: values.companyName,
      //   profile_picture: null,
      // };
      // // Include the password only if it has been provided
      // if (values.password && values.confirmPassword) {
      //   formData.password = values.password;
      // }
      
      const formData = new FormData();
      formData.append("full_name", values.fullName);
      formData.append("address", values.address);
      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);
      formData.append("user_role", "customer-manager");
      formData.append("company", values.companyName);
      if (selectedFile) {
        formData.append("profile_picture", selectedFile); 
      } else {
        formData.append("profile_picture", values.profile_picture); 
      }
      if (values.password && values.confirmPassword) {
        formData.append("password", values.password);
      }
      console.log("Try Block Values  : ", values);
      console.log("Try Block FromData: ", formData);
      console.log("Matched Properties");

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `/api/user/${values.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Sent Request");
      console.log("User created successfully:", response.data);
      
      const me = await axios.get(`/api/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const myData = me.data;      
      console.log("Fetched Me ...:", myData)    
      // const devicesResponse = await axios.get(`/api/device-manager/manager/${myData.id}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });      
      // console.log("devicesResponse:", devicesResponse) 
      let devicesResponse;
      try {
        devicesResponse = await axios.get(`/api/device-manager/manager/${values.userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (deviceError) {
        if (deviceError.response && deviceError.response.status === 404) {
          console.warn("No devices found for this manager after update.");
          devicesResponse = { data: [] };
        } else {
          console.error("Error fetching devices after update:", deviceError);
          return;
        }
      }

      // Unique farm IDs and names
      const farmIdsSet = new Set();
      const farmNamesSet = new Set();
      devicesResponse.data.map(device => {
        if (device.farm_id) {
          farmIdsSet.add(device.farm_id);
        }
        if (device.farm_name) {
          farmNamesSet.add(device.farm_name);
        }
      });
      // Sets into strings
      const assignedFarmIds = Array.from(farmIdsSet).join(", FID");
      const assignedFarmNames = Array.from(farmNamesSet).join(", ");
      const assignedDevices = devicesResponse.data.map(device => device.device_label).join(", ");
      console.log("Unique Farm Ids:", assignedFarmIds)  
      console.log("AssignedDevices:", assignedDevices)  

      setInitialValues({
        email: myData.email || "",
        phoneNumber: myData.phone_number || "",
        password: "",
        confirmPassword: "",
        fullName: myData.full_name || "",
        address: myData.address || "",
        companyName: myData.company || "",
        userId: myData.id || "",
        assignedDevices: assignedDevices || "", 
        farmId: assignedFarmIds || "",
        farmName: assignedFarmNames || "",
      });

      showCloseSuccessToast("Profile updated successfully!");
      navigate("/managerdashboard");
      // setSuccessMessage("The changes are saved successfully");
      // setTimeout(() => {
      //   setSuccessMessage("");
      // }, 3000);

    } catch (error) {
      // const formData = {
      //   full_name: values.fullName,
      //   address: values.address,
      //   email: values.email,
      //   password: values.password,
      //   phone_number: values.phoneNumber,
      //   user_role: "customer-manager",
      //   company: values.companyName,
      //   profile_picture: null,
      // };
      // console.log("Catch Block FromData: ", values)
      // console.log("Catch Block FromData: ", formData)
      console.error("Error creating user:", error);
      showCloseErrorToast("Failed to update profile");
    }
  };

  




  let range;
  if (isDesktop) {
    range = 4;
  } else if (isTablet) {
    range = 2;
  } else if (isMobile) {
    range = 1;
  }

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(
          `/api/device-manager/manager/${manager_id}`
        );
        setDevices(response.data);

        if (!localStorage.getItem("DeviceID") && response.data.length > 0) {
          const firstDeviceId = response.data[0].id.toString();
          localStorage.setItem("DeviceID", firstDeviceId);
          setSelectedDeviceId(firstDeviceId);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchDevices();
  }, [manager_id]);

  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.id;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/api/user/${userId}`);
        setUser(response.data);
      } catch (error) {
        console.error("Failed to decode token:", error);
      };
    }
    fetchUserData();
  }, []);

  useEffect(() => {
    const savedDeviceId = localStorage.getItem("DeviceID");
    setSelectedDeviceId(savedDeviceId);
  }, []);

  useEffect(() => {
    const fetchUnreadAlertCount = async () => {
      try {
        const response = await axios.get(`/api/notifications/alerts/count/${manager_id}`);

        if (response.data.success) {
          setUnreadAlertsCount(response.data.unreadCount);
        } else {
          console.error('Failed to fetch unread notifications count: ', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    };

    fetchUnreadAlertCount();

    const fetchUnreadMessageCount = async () => {
      try {
        const response = await axios.get(`/api/notifications/messages/count/${manager_id}`);

        if (response.data.success) {
          setUnreadMessagesCount(response.data.unreadCount);
        } else {
          console.error('Failed to fetch unread notifications count: ', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    };

    fetchUnreadMessageCount();

    // Optional: Refresh count every minute (60000 milliseconds)
    const intervalId1 = setInterval(fetchUnreadMessageCount, 60000);
    const intervalId2 = setInterval(fetchUnreadAlertCount, 60000);

    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    }; // Cleanup interval on component unmount
    
  }, [manager_id]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  const containerRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [isNotificationBoxOpen, setIsNotificationBoxOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setIsNotificationBoxOpen(!isNotificationBoxOpen);
  };

  const handleNotificationBoxClose = () => {
    setNotificationAnchorEl(null);
    setIsNotificationBoxOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (page) => {
    navigate(page);
  };

  const handleDeviceSelect = (device) => {
    localStorage.setItem("DeviceID", device.id.toString());
    setSelectedDeviceId(device.id.toString());
    window.location.reload();
  };

  const scroll = (direction) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollAmount = 300; // Adjust this value based on item width and spacing
      if (direction === "left") {
        container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  const getDeviceBackgroundColor = (deviceId) => {
    const savedDeviceId = localStorage.getItem("DeviceID");
    return savedDeviceId === deviceId ? "rgba(255, 255, 255,0.3)" : "#8fbaa6";
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor:"rgba(143, 186, 166, 1)",boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", color: "#000", zIndex: 1100 }}
      >
        <Toolbar>
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{
                position: "absolute",
                top: theme.spacing(2),
                left: theme.spacing(2),
                zIndex: theme.zIndex.drawer + 1,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              width: {
                xs: "calc(93% - 75px)",
                sm: "calc(95% - 180px)",
                md: "calc(100% - 230px)",
                lg: "calc(100% - 300px)",
              }, // Adjust width to fit within AppBar
            }}
          >
            {devices.length > range && (
              <ArrowButton direction="left" onClick={() => scroll("left")} />
            )}
            <ScrollContainer ref={containerRef}>
              {devices.map((device, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: 1,
                    margin: "0 10px", // Add margin to each item
                    borderRadius: "10px",
                    background: getDeviceBackgroundColor(device.id.toString()),
                    
                    height:"57px",
                    backdropFilter: "blur(10px)",
                    minWidth: "200px",
                    textAlign: "center",
                    flexShrink: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeviceSelect(device)}
                >
                  <DeviceStatusButton
                    status={device.active_status}
                    serialNumber={device.serial_no}
                    // deviceName={"Device " + device.id}
                    deviceName={device.device_label}
                  />
                </Box>
              ))}
            </ScrollContainer>
            {devices.length > range && (
              <ArrowButton direction="right" onClick={() => scroll("right")} />
            )}
          </Box>
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={unreadAlertsCount+unreadMessagesCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleClickOpen}
              color="inherit"
            >
               <Avatar
                    src={user?.profile_picture ? user.profile_picture : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}
                    alt="Profile"
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                    }}
                  />{" "}
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="md"  sx={{
    "& .MuiDialog-paper": {
      borderRadius: "5px",
      backgroundColor: "rgba(199, 221, 211)",
    },
  }} fullWidth>
        
        <DialogContent>
        <Box sx={{ display: "flex" }}>
        <Box
          component="form"
          noValidate
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 1,
              width: "100%",
              maxWidth: 830,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            {successMessage && (
              <div style={{ color: "green", fontWeight: "bold" }}>
                {successMessage}
              </div>
            )}          


          <Grid
              container
              alignItems="center"
              spacing={2}
              sx={{ maxWidth: 800 }}
            >
              <Formik
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur={false}
                // onSubmit={(values, { resetForm }) => {
                //   console.log(values);
                //   resetForm();
                // }}
                // initialValues={{
                //   phoneNumber: '',
                //   password: '',
                //   confirmPassword: '',
                //   fullName: '',
                //   companyName: '',
                //   assignedDevices:'',
                //   userId: '',
                //   email: '',
                //   farmId:'',
                //   farmName:'',
                // }}
                onSubmit={handleSubmitForm}
                initialValues={initialValues}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  resetForm,
                  touched,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Grid item xs={12}>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        marginTop: {
                          xs: "15px",
                          sm: "22px",
                          md: "2px",
                          lg: "5px",
                        },
                      }}
                    >
                      <Grid container spacing={2}>
                      <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          display="flex"
                          justifyContent="center"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            position="relative"
                            sx={{
                              marginTop: {
                                xs: "0px",
                                sm: "10px",
                                md: "3px",
                                lg: "10px",
                              },
                            }}
                          >
                          <Avatar
                            src={
                              selectedFile
                                ? URL.createObjectURL(selectedFile)  
                                : values.profile_picture           
                                  ? `/${values.profile_picture}`
                                  // : "/Images/profile_pic.jpg"
                                  : "/broken-image.jpg"
                            }
                            alt="User Profile Picture" 
                            sx={{
                              width: { xs: 80, sm: 100 },
                              height: { xs: 80, sm: 100 },
                            }}
                          />
                          <Box
                            position="absolute"
                            bottom={0}
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <IconButton aria-label="edit" onClick={handleEditClick}>
                              <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => handleDeleteClick(setFieldValue)} >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                          </Box>
                        </Grid>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={handleFileChange}
                        />
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>User ID</Typography>
                          <TextField
                            fullWidth
                            id="userId"
                            name="userId"
                            variant="outlined"
                            value={"UID"+values.userId}
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.userId && !!errors.userId}
                            helperText={touched.userId && errors.userId}
                            placeholder="AGR001"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Full Name */}
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={6}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Full Name*</Typography>
                          <TextField
                            fullWidth
                            id="fullName"
                            name="fullName"
                            variant="outlined"
                            value={values.fullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fullName && !!errors.fullName}
                            helperText={touched.fullName && errors.fullName}
                            placeholder="Full Name"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Phone Number */}
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Phone Number*</Typography>
                          <TextField
                            fullWidth
                            id="phoneNumber"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                            variant="outlined"
                            placeholder="Phone Number"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Email */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Email*</Typography>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            variant="outlined"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            placeholder="Email"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Company Name */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Company Name</Typography>
                          <TextField
                            fullWidth
                            id="companyName"
                            name="companyName"
                            variant="outlined"
                            value={values.companyName}
                            onChange={handleChange}
                            disabled
                            onBlur={handleBlur}
                            error={touched.companyName && !!errors.companyName}
                            helperText={
                              touched.companyName && errors.companyName
                            }
                            placeholder="Company Name"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Farm Id */}
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Farm ID</Typography>
                          <TextField
                            fullWidth
                            id="farmId"
                            name="farmId"
                            variant="outlined"
                            value={"FID"+values.farmId}
                            onChange={handleChange}
                            disabled
                            onBlur={handleBlur}
                            error={touched.farmId && !!errors.farmId}
                            helperText={touched.farmId && errors.farmId}
                            placeholder="Farm 01S"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Farm Name */}
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Farm Name</Typography>
                          <TextField
                            fullWidth
                            id="farmName"
                            name="farmName"
                            variant="outlined"
                            value={values.farmName}
                            onChange={handleChange}
                            disabled
                            onBlur={handleBlur}
                            error={touched.farmName && !!errors.farmName}
                            helperText={touched.farmName && errors.farmName}
                            placeholder="Farm Name"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Devices */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>
                            Assigned Devices
                          </Typography>
                          <TextField
                            fullWidth
                            id="assignedDevices"
                            name="assignedDevices"
                            variant="outlined"
                            value={values.assignedDevices}
                            onChange={handleChange}
                            disabled
                            onBlur={handleBlur}
                            error={
                              touched.assignedDevices &&
                              !!errors.assignedDevices
                            }
                            helperText={
                              touched.assignedDevices && errors.assignedDevices
                            }
                            placeholder="Assigned Devices"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Address</Typography>
                          <TextField
                            fullWidth
                            id="address"
                            name="address"
                            variant="outlined"
                            value={values.address}
                            onChange={handleChange}
                            disabled
                            onBlur={handleBlur}
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                            placeholder="Farm Name"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                          />
                        </Grid>
                        {/* Password */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>Change Password</Typography>
                          <TextField
                            fullWidth
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            variant="outlined"
                            placeholder="Change Password"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "10px",
                              md: "3px",
                              lg: "10px",
                            },
                          }}
                        >
                          <Typography gutterBottom>
                            Confirm Password
                          </Typography>
                          <TextField
                            fullWidth
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.confirmPassword &&
                              !!errors.confirmPassword
                            }
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            variant="outlined"
                            placeholder="Confirm Password"
                            sx={{
                              "& .MuiInputBase-root": {
                                "&:after": {
                                  borderBottomColor: "green",
                                },
                              },
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                              "&:-webkit-autofill": {
                                WebkitBoxShadow:
                                  "0 0 0 1000px rgba(199, 221, 211) inset",
                                WebkitTextFillColor: "black",
                                transition:
                                  "background-color 5000s ease-in-out 0s",
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle confirm password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid
                          container
                          justifyContent="flex-end"
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            sx={{
                              marginTop: {
                                xs: "20px",
                                sm: "10px",
                                md: "1px",
                                lg: "10px",
                              },
                              ml: 2,
                             
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="success"
                              fullWidth
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            sx={{
                              marginTop: {
                                xs: "20px",
                                sm: "10px",
                                md: "1px",
                                lg: "10px",
                              },
                              ml: 2,
                              
                            }}
                          >
                            <Link href="/managerdashboard">
                              <Button variant="outlined" fullWidth>
                                Cancel
                              </Button>
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Formik>
            </Grid>
          </Paper>
        </Box>
      </Box>



        </DialogContent>
        </Dialog>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <SideBar3 open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <NotificationBox
        anchorEl={notificationAnchorEl}
        open={isNotificationBoxOpen}
        onClose={handleNotificationBoxClose}
      />


<div style={{ flexGrow: 1 }}>
        <Outlet /> {/* This will render the nested routes */}
      </div>
    </Box>
  );
}
