// toastConfig.js
import { toast } from 'react-toastify';

const defaultCloseToastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const defaultOpenToastConfig = {
  position: "top-right",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const showCloseErrorToast = (message) => {
  toast.error(message, defaultCloseToastConfig);
};

const showCloseSuccessToast = (message) => {
  toast.success(message, defaultCloseToastConfig);
};

const showCloseInfoToast = (message) => {
  toast.info(message, defaultCloseToastConfig);
};

const showCloseWarningToast = (message) => {
  toast.warning(message, defaultCloseToastConfig);
};

const showOpenErrorToast = (message) => {
  toast.error(message, { ...defaultOpenToastConfig, autoClose: false });
};

const showOpenSuccessToast = (message) => {
  toast.success(message, { ...defaultOpenToastConfig, autoClose: false });
};

const showOpenInfoToast = (message) => {
  toast.info(message, { ...defaultOpenToastConfig, autoClose: false });
};

const showOpenWarningToast = (message) => {
  toast.warning(message, { ...defaultOpenToastConfig, autoClose: false });
};

export { showCloseErrorToast, showCloseSuccessToast, showCloseInfoToast, showCloseWarningToast, showOpenErrorToast, showOpenSuccessToast, showOpenInfoToast, showOpenWarningToast };
