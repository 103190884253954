import React from "react";




function MySVGComponent({ onDistrictClick }) {
  return (
    <div>
      <style>
        {`
         
          .responsive-svg {
            width: 520px;
            height: 520px;
          }

          /* Mobile view */
          @media (max-width: 600px) {
            .responsive-svg {
              width: 350px; /* Adjust the width as needed for mobile view */
              height: 440px; /* Maintain aspect ratio */
            }
          }
        `}
      </style>
      <svg
       className="responsive-svg"
        baseProfile="tiny"
        fill="#6f9c76"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".5"
        version="1.2"
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg" // Corrected the namespace
      >
        <g id="features">
          <path
            d="M534.5 241.9l0.1 3.6 2.7 2-2.4 2.3-2.2 2.6 3.6 2.8 3.9-0.3 3.2-2.9 2.4-4.2 1.5-3.9 0.1 0.1 8.3 10.1 7.4 12.7 3 3.1 4.2 1.3 3.4 2.7 5 12.1 1.2 1.3 1.3 1.4 0.1 0 5.6 2.6 2.9 6 3.2 12.2 0.6-2.2 0.7-0.9 1.3 0.3 2.2 1.2-0.6 2.3 6.4 7 2.1 3.5 0 4.2-1.7 1.7-2.4 1.1-2.2 2.5 0.1 0 1.6-0.4 1.8-0.5 0.4-0.2 0.8-0.5 0.1 0.1 5 10.9 0.7 5.6-5.7 1-0.1 0 1.2-3.8-1.5-3-2.6-0.4-1.8 4 0.6 3.1 1.7 2.2 0.9 2.4-1.7 3.5-1.4-1-2.5-1.9-4-2.5-4-1-3.9 1.7-3 4 1.1 2.1 3.6-0.5 4.8-4 3.3 3.5 2.9 4.2 3.6 3.5 5.1 1.5 10.6-0.2 3.6-2.3-2.4-5.4 6.1-2.8 2.4-0.8 2.5 0.2 0.1 0 2.8 1.6 2.5 2.8 1.9 3.2 1.7 9.3 3.6 12 0.1 3.1 0.1 3.2-2.9-2.1-3.1-6.2-2.7-1.3-0.1 1.7 1.1 7.6 0.1 0.5 1.3 2.9 1.5 0.8 1.4 0.6 0.1 0 3.1 0 2.4 1.2 1.5 8.9 1.8 6.2 0.3 1 0.5 3.8-20.7-2.2-3.9 1.3-3.5 0-2.9 0-2.1 2.3-1.5 2.8-1.8 1.3-1.6 1.4-1.4 6.1-1.2 2.2-3.3 7.6-5.7 1.4-1.2-0.4-0.9-0.7 2.7-1.7 1.2-2.9-0.8-4.4 0.1-3-0.3-2.9-1.7-2.4-2.9 0.3-4.1-2.1-3.7-2.9-3.8-2.2-4.1 0-17.5-4.3-9.7 1-2.9-2.9-0.9-4.9-3.6-2.7-3.7-1.5-2.7-4-3.5 1 5-17.9 0.6-2.9 0.9-2.8 2.9-2.8 3.2-2.2 1.9-3.4-1.5-3.6-2.7-2-2-2.7-3.3-7.7-6.1-9.1-0.9-5.6-2.5-2.4-1.9-2.2-0.2-4.8 3.5-8.3-1.7-4.9-4.3-9.4-0.1-10 3.9-5.8 2.7-6-2.2-1.7-1.9-2.5-3.5-3.9-10.1-7-5.8-3.4-6.8-0.1-6.5 2.6 1-4 1.9-3.4 9.1-4.2 10.6-1.4 4.5-1.3 4-2.3 4.4-1.3 3.6-1.6z"
            fill="#f8dace"
            stroke="black"
            onClick={() => onDistrictClick("ත්‍රිකුණාමලය")} // Call the parent function
          />
          {/* Label for the district */}
          <text x="530" y="300" fill="black" fontSize="20" fontWeight={900}>
            {"ත්‍රිකුණාමලය"}
          </text>
          <path
            d="M534.5 241.9l-3.6 1.6-4.4 1.3-4 2.3-4.5 1.3-10.6 1.4-9.1 4.2 4.3-15.8-2.8-3.7-7.4-4.5-7.2-1.1-3.4 2.3-3.6 0.9 0.4-3.2 2.2-3.3-2.7-4.7-6.2-0.7-0.8-7.5-7.2-2.7-7.5 0.2-0.5 1.1-0.4 1.2-2.2 1.3-2.3 1.1-4 3.5-5.6 2.5-3.6-5.3-5.7-3.6-8 0.2-4.4-0.3-4.2 0.4-0.6 3.4 1.8 2.5 3.4 0.5 0.2 1.9 0 2.1 0.9 4 0.2 3.9-4.1 6.3-6.4 5.2-10.7 6.8-38.1 2 3-8.4-1.1-1.6-1.1-2.1 0.8-2.7 1.2-2.6 0.8-2.6 1-2.3 5.1-1.7 0.3-4.8-0.5-5.5 0.9-3.4 0.1-3.5-0.2-1.8-0.5-1.6-4.2-6.8-1.4-7.6-0.3-8 6.1 0.8-0.6-6.5 6-7.9 17.7-2 6.3-0.1 6.2 1.1 6.1-0.6 4-10.1 3.1-0.1 10.5 0.6 5.8-0.4 3.9-2.1 3.2-10.6 1.1-0.3 1.7 2.2 4.6 3.8 5.2 2.9-0.6-5.3 0.7-5.3 4.9-3.9 0.5-3.9 1.1-1.1 1.2-0.8 1.4-1.2 27.3 22.2 0.2 0.4 1.7 2.7 2.5 3.3 5 4.7 3.1 4-2.7 1.5-0.1 0-1.5-0.7-0.2-0.1-0.1 0-2.4-3.1-1.3-0.7-4.2-0.7-1.8 0.5 0.9 1.9 0.3 0.6 11.9 11.2 4.7 2.6-1.8-2.9-1.1-2.7 0.5-1.9 3.2-0.8 1.5 1 2.5 7.3 2.2 4.4 8.8 17.6-1.8-0.6-4.9-1.9-3 0-1.3 3.3 1.8 0.8 3.7 0.7 3.4 2.1 0 0.3 0.5 5 1.6 0 0.7-1.3 0.7-0.6 0.8-0.4 1.1-0.7 1.1 4.6 0.2 0.6 3.2 7.5 4.4 6.7 4.5 2.9 0.3 0.3 0.5 0.4 0 1.7-1.1 1.6-2.1 0.8-0.6-0.7-2.6-4.1-2.7-1.5-6.3-1.6-3.7-1.7 1.7 2.4 0.9 1.3 8.3 7.8 0 0.8z"
            fill="#b7d65f"
            stroke="black"
            onClick={() => onDistrictClick("මුලතිව්")}
          />

          <text x="400" y="200" fill="black" fontSize="20" fontWeight={900}>
            {"මුලතිව්"}
          </text>

          <path
            d="M466.1 133.1l-1.4 1.2-1.2 0.8-15.7-10.8-5.4-2.5-4.8-1.1-0.7-0.3-0.7-0.3-4.1-3.2-1.6-0.9-1.6-0.3-1.9 0.1-0.4-2.3-3-9 0.6 0.3 0-1.8-8.4-4.2-20.3-16.7-16.6-19.2-8.4-4.6-13.2-3.1-3.5-2.5-0.5-3.6 4.9-2.1 1.5-0.3 4.8-0.8 9.6-0.4 4.6 0.8 3.2 2.3 2.1 7.6 2.1 3.2 30.2 35.7 4.4 3.2 9 5.2 36.4 29.6z m-85.3-37.6l-11.8-5.2-4.3-2.5-2.9 0 0.6 5.7 1.6 1.6 2.4 1.9 1.5 2.3-1.5 3-1.7 0.6-1.7-1-1.5-1.3-4.5-2.3-8.6-7.5-4.9-2.1 2.5 2.8 4.5 3.9 2.6 2.8-5.6-1-5.5-2.3-11.1-6.2-8.9-7-5.2-1.9-0.5-0.3-2-1.3-1.9-0.8-0.3 1.7-2.5-1.7-1-2.3-0.4-2.7-0.9-3-1.5-2.6-0.9-1.2-2.4-3.1-1.5-2.7 5-2.5 9.1-8.5 4-1.8 0.1 0 18 1.6 9.4-1.1 3.4 0.4 1.4 3.2-0.3 4.9 0.7 1.7 2 0.6 1.9 0.3 5.5 1.4 1.2 0.7 1 2 2.5-0.1 1.9-0.6 0.8-0.2 1.1-0.2 0.8-0.1 1.8 1.1 0.8 0.4 6.4 7.1 9.3 10.1 0 0.1-3.1 6.7-4.9 8.5z m-121 27l-1.9 1.7-1.9 1.8-5.8 0.4-5.4-1.8-2.3-3 0-9.6 0.6-2.4 1.6 0.3 1.5 2 1 2.4 4 1.3 4.7 0.4 1.2 0.8 2.7 5.7z m38-27.4l-3.6-2.6-3.5-5.4-5.6-11.2 2.5-4 0.6-5.1 1.5-4.2 5-1.1 2.6 0.8 0.1 0 0.2 2-0.9 2.8-0.6 3.1 0.1 3.2 0.3 2.6 0 0.2 0.2 0.5 0.8 1.8 1.1 1.2 0.8 1 1 0.2 4.5-0.2 0.7 0.7 0.3 3.5 0.6 1.4 2.6 1.7 3.6 1.5 3 1.7 0.2 0.6 0.8 1.7-1.6 1.6-3 0-3.3-1-2.3-1.4-1.6-0.4-4.2 2.4-2.9 0.4z"
            fill="#c4df9c"
            stroke="black"
            onClick={() => onDistrictClick("යාපනය")}
          />

          <text x="310" y="70" fill="black" fontSize="20" fontWeight={900}>
            {"යාපනය"}
          </text>

          <path
            d="M423.6 104.5l3 9 0.4 2.3-2.8 0.2-1.8 0.7-0.3 0.4-0.5 0.8-0.9 0.5-3.1-2.3-1.2-0.2-7.5 0.2-2.1-0.6-16.4-14.3-7-4.6-0.1 0-2.5-1.1 4.9-8.5 3.1-6.7 0-0.1 11 12.1 7.2 4.5 16.6 7.7z m39.9 30.6l-1.1 1.1-0.5 3.9-4.9 3.9-0.7 5.3 0.6 5.3-5.2-2.9-4.6-3.8-1.7-2.2-1.1 0.3-3.2 10.6-3.9 2.1-5.8 0.4-10.5-0.6-3.1 0.1-4 10.1-6.1 0.6-6.2-1.1-6.3 0.1-17.7 2-6 7.9 0.6 6.5-6.1-0.8-4.4 3.3-4.9 2.5-7.9 0.7-1.7 0 1.4-5.7 0-13.6 0.5-2-0.4-1.3-2.7-0.5-6.4-3.3-2.4-2.4-1.6-4.4-0.3-0.8-1.1-5.4-0.1-3.1 2.8-3 5.1-2.5 5.6-1.8 4.7-0.7 2.1-1.1 5-6.7 0.6-0.5 0.9-0.7 1.7-1 2.1-0.8 2.6-0.4-2.2-4-1.3-1.8-1.3-1.8-3.4-3.4-4.5-3.4-17-10-3.5-4.3 7.4 0.2 7.1 2.9 13.9 8.1 13.2 3.3 3.5 2.3 5.5 4.8 1.3 2.5-2.8 1.4 0.6 1.6 0.8 1.6-1.4 1.6 4.8 3.3 11.4-2.8 20.8-8.3 5.7 0.5 12.2 3.4 14.5 6.6 11 2.1z"
            fill="#aed690"
            stroke="black"
            onClick={() => onDistrictClick("කිලිනොච්චි")}
          />

          <text x="360" y="150" fill="black" fontSize="20" fontWeight={900}>
            {"කිලිනොච්චි"}
          </text>
          <path
            d="M366 183.9l0.3 8 1.4 7.6 4.2 6.8 0.5 1.6 0.2 1.8-0.1 3.5-0.9 3.4 0.5 5.5-0.3 4.8-5.1 1.7-1 2.3-0.8 2.6-1.2 2.6-0.8 2.7 1.1 2.1 1.1 1.6-3 8.4 38.1-2 6.2 2.9 3.6 5.7 3.5 9-1.2 8.1-12.3 4.6-13 1.6-8.4-0.9-6.4 3.6-3.5 7.8-5.9 0.6-0.9 3.5 0 3 5.4 4.2 8.8 11.3-22.5 5.9-11.3-0.1-10.2-4.2 1.8 13.7-0.5 21.6-2.2-0.3-1.9-0.9-6.2-3.5-5.9-2.5-6.8-0.6-6.1-2.3-2.6-1.6 0.9-0.9 0.3-1.2 2.3-7.1 0.3-0.3 0.2-0.2 0.5-2 2.2-4.7 2-22.7-5.5-14.5 0.4-2.5 0.4-16.5 0-0.2-0.5-5.6-0.7-2.7-1.2-2-1-2.4 2.2-1.1 3.1-0.6 2-0.6 13.4-12.1 7-3.5 2.9-2.5 1.5-5.7 2.8-5.9 1.5-8.1 7.5-19.2 0.2-1.1 0.7-2.8 1.7 0 7.9-0.7 4.9-2.5 4.4-3.3z m-110.1 37.7l-2.6-1-1.1-2.2 0.7-2.3 3-1 10.3 0 4.6 1.2 4.7 1.2 9.8 3.8 9.4 5.4 7.9 7.7-0.1 0-3.7-0.8-6.7-3.3-3.9-0.7 6 6.8 4.7 5.4 2.1 3.7-5.5-0.7-3.6-2.8-2.5-3-1.9-1.4-2.3-1.2-9.5-8.5-4.7-2.1-4.8-2.2-3.4-0.9-1.8-0.5-5.1-0.6z"
            fill="#c3e0b2"
            stroke="black"
            onClick={() => onDistrictClick("මන්නාරම")}
          />

          <text x="320" y="270" fill="black" fontSize="20" fontWeight={900}>
            {"මන්නාරම"}
          </text>
          <path
            d="M301.7 339.1l2.6 1.6 6.1 2.3 6.8 0.6 5.9 2.5 6.2 3.5 1.3 9.6-4.2 8.9-7.2 5.9-0.3 1.7-1 2.1-3.9 1.8-2.6 5.7 3.4 6.6 5.8 23.4 1.4 4.2 4.8 0 3.9-1.8 4.1 3.1 4.7-0.2 3.4 2.8 2.5 3.3 0.5 4.4-0.1 4.7 2.4 9.2 0.8 4.6 2.2 4.1 3.2 1.1 2.7 1.8-0.6 2.8-0.9 3 0.2 6.3-0.2 5.3-1.7 2.6-1.4 2.7 2.8 5.2-2.9 2.2-4.8-2.7-1.3 2.3-0.1 2.8-1.8 1.9-1.4 0.4 0.2-0.4 0.2-0.4-3.4 1.6-1.4 3.2-0.4 2.4-0.9 2.1-4.9 2.5-1.1 2.4-0.6 2.5-2.8 1.9-3 1.6-0.9 2.5-0.4 2.8-2.2 5.6-0.7 6.2 1.2 2.6 0.9 2.4-1.6 2.2-2.5 1-3.3 0.3-3 0.9-1.6 2.7-1.1 3 2.8 0.7 1.3 2.2-2.8 1.5-3.3 0.8-1.6 2.3-0.9 2.7-3.5 5.9-2.5 6.4-0.4 8 0.4 8 8.5 45.8 0.6 13.8-4-1.6-3.4 2.2-2.7-0.4-1.8-2-4.5 0.6-4.3 1.1-5.5-0.2-9.9-73-1.4-3 0.9-1 0.7-0.1 1.7 1.1 0.4-4 1.1-4.7 0.3-2.2 0.3-2.3-1.3-3.5-1.8-3.2-0.3-2.1-0.3-2.1 0-8.6-5.3-26.1-0.7-3-2.7-5.7-2.3-12.1-3.8-9.5-5.8-26.2 0-7.5 1 0.5 0.1 0.1 0 0.2 0.5 0.8 0.8-4.3-1.2-13.8-1.2-4.1 3.8-2.5 3.6-4.7 2.6-5.3 1-4.4 1.1-2.9 6.8-10.8-0.1 0.2-3.4 8.2-4.4 7.7 1 1.9 0.2 0.4 1.7 1.8 2.2 0.8 2.8 0-2.3 6.4-2.3 4.6-1 0.8-1.1 0.1-0.8 0.7-0.4 2.4 0.2 5.6-0.2 1.7-3.9 12.4 0.2 2.4 0.3 4.6 4 4.8 1.1 1.3-4.6 9.7 7.4 5.4 10.7 0.8 5.4-3.9-1-4.1-1.8-3.2-0.6-1.3-0.9-1-2-2.6-2.3-1.5-0.7-2.5 7.8-14.8-0.3-2.7-1-2-1.2-1.7-0.7-1.7-0.2-2.8 0.3-4.9-0.1-1.9 0-0.1-0.1-0.4-0.6-1.6-0.4-0.4-0.5-0.4-0.4-1 0.4-2.6 1.1-2.2 3-3.9 0.7-2.6 0.5-4.9 2.1-9.7 2.2-22.8 1.4-2.6 1.5-1.3 1.2-1.5 0.5-3.4 0-9.7 1.3-4.8 3.1-3 3.8-2.6 2.1-2.3z"
            fill="#fdddd0"
            stroke="black"
            onClick={() => onDistrictClick("පුත්තලම")}
          />

          <text x="280" y="460" fill="black" fontSize="20" fontWeight={900}>
            {"පුත්තලම"}
          </text>
          <path
            d="M282.1 640.8l5.5 0.2 4.3-1.1 4.5-0.6 1.8 2 2.7 0.4 3.4-2.2 4 1.6 4.9-1.9 8.8-6.9 5.5-0.2 6.4 4.4 6.6-1.9 5.7-4 6.4-1.2 2.1 2.3 0.6 2 1.4 1.9 5.7 6.6 4 2.9 0.4 3.1-2.3 1.8-0.6 8-3 2.8-3.6 1.9-1 6.6 2.8 5.8 5.6-1.3 4.9 1.4-1.7 3.9-3.4 2.4-2.9 6.7-1.2 6.8 3.4 11 0.1 2.1-0.7 1.9-4.2 1.5-6.2-0.6-4.6 2.1-3.7 3.6-0.9 4.4-0.3 4.5-4.9 0.3-4.1-0.5-2.7-1.1-2.6-1.4-2.7-2.5-3.1-1.9-3.9 0.2-4 0.8-11.5-3.3-2.1 0.8-1.8 1-4.1-1-3.8-2-4.9-1.9 2.3-5.5 0.6-2.6-0.1-2.7 0-0.1-11-39.2 1.5-5.8-0.1 3.6 0.9 2.2 1.2 1.7 1.1 2.1 2.2 7.7 1.1 1.9 1.4 0 0.4-2.2 0.6-0.9 0.6-0.2 0.2 0.1-0.1-7.1-0.5-3.1-1.2-2.6-1.1-0.7-3-0.7-1.3-0.9-0.2-0.3-0.8-1.7 0.4-1.9 0.8-1.7 0.5-1.6-1.4-10z"
            fill="#e8f0ef"
            stroke="black"
            onClick={() => onDistrictClick("ගම්පහ")}
          />

          <text x="300" y="680" fill="black" fontSize="20" fontWeight={900}>
            {"ගම්පහ"}
          </text>
          <path
            d="M287.1 713l4.9 1.9 3.8 2 4.1 1 1.8-1 2.1-0.8 11.5 3.3 4-0.8 3.9-0.2 3.1 1.9 2.7 2.5 2.6 1.4 2.7 1.1 4.1 0.5 4.9-0.3 0.3-4.5 0.9-4.4 3.7-3.6 4.6-2.1 6.2 0.6 4.2-1.5 5 0.4 4 2 0.7 4.2-3.1 2.9 0.3 2.2 0.1 2.3-2.9 3.6-2.5 4-1 3.2-1.2 2.8 5.7 6-5.1 4.6-6.7-1.6-7.5 3.2 1.5 6.7-4.5-1.2-3-6.9-1.2-0.7-1.5-0.3-2.8 3.3-3.6 1.8-2.5-1.1-2.6 0.3-8.9 7.6-2.5 1.1-2.7 0.7-6.7 2.5-10.6-6.7-0.3 3.9 1.4 4.3 0.7 3 0.1 2.9-0.1 0.1-0.4 0.6-8.6-22.1-3.1-17.5-0.3-1.7 0-16.7 0.3-0.7z"
            fill="#d2e2e2"
            stroke="black"
          />

          <text x="300" y="740" fill="black" fontSize="20" fontWeight={900}>
            {"කොලඹ"}
          </text>
          <path
            d="M298.8 771.7l0.4-0.6 0.1-0.1-0.1-2.9-0.7-3-1.4-4.3 0.3-3.9 10.6 6.7 6.7-2.5 2.7-0.7 2.5-1.1 8.9-7.6 2.6-0.3 2.5 1.1 3.6-1.8 2.8-3.3 1.5 0.3 1.2 0.7 3 6.9 4.5 1.2-1.5-6.7 7.5-3.2 6.7 1.6 0.8 4.7 4 10.7-0.3 3.7 1.4 4.4 2.3 4.6 7 6.8 1.4 6.8 2.5 2.4 3.2 1.2 5.2 4.7 3.3 6.4-3.5 1.4 1.3 3.9 9.7 13.7 8.3 14.5-8.2 1.9-8 5-4.3 1.9 0 4.9 1.7 5.1-0.4 4.6-3.6-2.8-3.6-3.5-6.8-5.2-7 0.6-6.3 3.5-9.9-1.9-3.9-2.6-3.7-3.2-4.6-2-4.2-0.9-8.4-3.9-1.2-2-1.7-1.6-2.3 0.9-1.5 1-1.4-1.2-1.2-1.7-1.6-0.4-1-4-0.5-0.4-1-0.6-1-0.9-0.6-1.2 0.2-1.4 1.1-0.3 1.2 0.1 0.6 0-1.8-12.2-0.1-1-4.8-12.3-11.2-28.7z"
            fill="#c2d7d4"
            stroke="black"
            onClick={() => onDistrictClick("කළුතර")}
          />

          <text x="315" y="800" fill="black" fontSize="20" fontWeight={900}>
            {"කළුතර"}
          </text>

          <path
            d="M317.7 834.6l1.6 0.4 1.2 1.7 1.4 1.2 1.5-1 2.3-0.9 1.7 1.6 1.2 2 8.4 3.9 4.2 0.9 4.6 2 3.7 3.2 3.9 2.6 9.9 1.9 6.3-3.5 7-0.6 6.8 5.2 3.6 3.5 3.6 2.8 0.4-4.6-1.7-5.1 0-4.9 4.3-1.9 8-5 8.2-1.9 2.8 2.9 3.2 2.7 3.9 1.6 3.4 0.1 7.2 3.5 3.7-0.1 2 2.9-4.8 1.4-3.1 3.1 2.7 4.2 3.6 3.5-1.4 6.6-7.1-1.6-4.4-4.2-4.9-3.6-3.8-1.2-3.7 1 2.3 4.3 4.6 2.9 4.8 7 1.8 6.6-4.1 5-8.1-1.4 2.4 2.8 1.1 3.8-1.6 4.8-2.1 4.5 1.5 4.5 3.5 3.4 4.1 2.8 3.7 3.3-3.2 3.7-3.6 3.7-3.2 1.3-1.4 2.2 0.7 2.4 1.8 1 4.1 1.7-1.4 2.8-4.2 1.5-2.5 4.5 0 2.6 0.4 2.4 0.1 0.3-1.5-0.3-1.1-0.5-0.8-1-1-1.1-1.8-0.5-1 0.3-0.9 0.8-0.9 0.6-1.2 0-0.4-0.3-0.4-0.4-1.4-2-0.8-0.5-0.1-0.1-1.7-0.6-9.8-3.5-4.1-0.9-2.8-0.6-5-4-1.2-0.5-2.2-1.1-1.7 1.7-4.3-3.5-18.8-19.9-1.5-1.2-0.2-0.2-0.5-0.7-0.3-0.9 0.1-2-0.6-1-0.8-1.1-2.1-2.7-10.6-22.5-0.5-2.1 0.1-2 0-1.9-0.4-1.2-1.9-4-1.9-10.8-4.3-7.6-2.6-10.9z"
            fill="#b7d65f"
            stroke="black"
            onClick={() => onDistrictClick("ගාල්ල")}
          />

          <text x="350" y="890" fill="black" fontSize="20" fontWeight={900}>
            {"ගාල්ල"}
          </text>
          <path
            d="M410.6 945.3l-0.1-0.3-0.4-2.4 0-2.6 2.5-4.5 4.2-1.5 1.4-2.8-4.1-1.7-1.8-1-0.7-2.4 1.4-2.2 3.2-1.3 3.6-3.7 3.2-3.7-3.7-3.3-4.1-2.8-3.5-3.4-1.5-4.5 2.1-4.5 1.6-4.8-1.1-3.8-2.4-2.8 8.1 1.4 4.1-5-1.8-6.6-4.8-7-4.6-2.9-2.3-4.3 3.7-1 3.8 1.2 4.9 3.6 4.4 4.2 7.1 1.6 1.4-6.6-3.6-3.5-2.7-4.2 3.1-3.1 4.8-1.4 4.5-0.7 4.5-1 1.6-3.8 3.9 0 10.2-1.4 3.3 6.2-1.5 4.1 4.1 0.7 5.5-0.9 2.6 3.2-0.2 2.2 3 2.2-1.2 1.2 0 2.2-2.2 0.9 0.4 1.7-1.6 4.1 1.6 3.7-8.9 6.7-2.8 9.9 4 2.9 4.4 1.6 2-0.3 1.3 1.2-1.1 2.4-1.4 2.1 0.8 4 3.8 2 2.7-0.4 2.5 0.5-0.8 2-2.1 2 2.5 8.9-7.5 3.2 0.6 3.7 3.2 2.1 2.2 0.2 2 1.1-0.1 1.9-1.7 0.9 2.8 4.2 5.8 2.7 1.3 0.7-3.5 4.1-9.5-0.5-3.9 0.5-3.1 1.2-3.2 1.8-5.6 4.2-1.6 0.4-1.6 0.3-3.8-1.4-4.1-2.1-2.3-0.7-1.6-0.5-13.4 1.6-3.8-0.8-1.9-1.9-1.2-2.5-1-1.5-0.7-1.1-3.9 2.3-3.5 0.3-6.2-0.9z"
            fill="#c3e0b2"
            stroke="black"
            onClick={() => onDistrictClick("මාතර")}
          />

          <text x="420" y="910" fill="black" fontSize="20" fontWeight={900}>
            {"මාතර"}
          </text>
          <path
            d="M490 942.5l-1.3-0.7-5.8-2.7-2.8-4.2 1.7-0.9 0.1-1.9-2-1.1-2.2-0.2-3.2-2.1-0.6-3.7 7.5-3.2-2.5-8.9 2.1-2 0.8-2-2.5-0.5-2.7 0.4-3.8-2-0.8-4 1.4-2.1 1.1-2.4-1.3-1.2-2 0.3-4.4-1.6-4-2.9 2.8-9.9 8.9-6.7-1.6-3.7 1.6-4.1-0.4-1.7 2.2-0.9 13.1 2.6 16.9 6.1 4.5 0.7 4.4-1.1 4.4-0.4 3.5 2.8 2.7 3.1 8.4 1.7 4.9 1.6 1.1-4.1-2.1-5.9-4.3-4.7 5.7-3.7 5.8-3.2 6.5-2.1 5-3.8-2.2-5.2 1.1-5.2 2.9 0 3.2-0.3 1.9-2.6-0.1-3.1 2.9 2 2.1-2.5 5.8 0 5.8 0.7 3.9 1.4 1.7 4.2 2.3 0.4 2.5-0.1 2.1-1.5 2.9-0.4 4.3 5.7 3.6 6 5.7 2.4 6.3-3.5 2.3-2.3 3.5-1.4 6.7-1.8 13.7-5.3 2.6-1.8 0.5 0.6 2.1 0.3 6.1-2.2 6.4 1.4 3-4.9 0-2-0.1-1.9 1-1.4 1-1.7-1.1-3.1-0.1-3.9 4.5-3.7 5.5-2.4 4.2-4.1 4.9-3.7 6.2-1.1 4.1-4.9 6.9 8.5 2.6 0.2 3 0.4 3.8 4.4 5.6 1.8 2.1 0.6-7.1 11.2-7.2 7.3-13.4 9.5-4.4 1.3-5.8 3.1-42.8 33.7-8.6 4.7-27.8 9.8-0.4-3.3 0-0.2-1.6-1.3-0.1 0-0.3 0.1-1.4 0.4-0.9 1.8-0.5 3.9-1.2 1.5-2 0.6-2.7 1.1-5 3.1-5 2.2-24 5.1-11.7 4-12.7 2.4-5.8 2.2-3.5 4.9-6.3-1.2-3.8 1.5-2.2 0.8-8.9 6.6-8.8 6.6-0.6 0.6z"
            fill="#b7d65f"
            stroke="black"
            onClick={() => onDistrictClick("හම්බන්තොට")}
          />

          <text x="520" y="900" fill="black" fontSize="20" fontWeight={900}>
            {"හම්බන්තොට"}
          </text>
          <path
            d="M716.5 818.5l-2.1-0.6-5.6-1.8-3.8-4.4-3-0.4-2.6-0.2-6.9-8.5 6-85.1-1.2-4.6-2.7-4 0-4.5-1.6-4.4 1-4.6 0.5-4.4-6.8-4.1-8.2-2.1-1.2-1.1-1.5-0.8-1.7 0-1.8-0.8-1.8-3.2-1-3.6-2.9-5.5 2.7-7.2 4.8-5.7 3.7-2.9-5-7-0.7-5-3.2-5.6-5.1-4.4-0.9-4.5 0.3-5.6-2.7-12.2 0.4-3.1-1.5-0.3-1.4-0.9 3.4-5.3-4.6-1.2-5.7 3.1-2.8 1-2.6 1.1-0.8 2.2-1.6 2.5-3.2 0.8-2.3 0.2 0 3 1.3 2.7 0.2 2.8-1 2.3-1.6 1.9-1.1 1.6-4.7 2.6-5.7-0.2 0.1 8.9 1.2 8.7-4.3 3.1-4.7 1.6-2-3.8-1.8-4.4-0.8-4.2-2.1-4.2-1.6-2.7-1-2.1-2.8-0.5-2 0.5-3.4-3.4-2.6-5.5-1.5-10.2-0.9-2.9-0.7-2.9 0.6-2.1 1.3-2.4 2.4-11.3-0.8-11.6-10.3-2.2-11.3 6.3-4.9 4.6-2.8 1.1-2.3 0.5-4.2 1.5-5.7-9.5-1.8-5.6-0.3-3.8-1.5-3.3-3.5-2.1-0.6-0.6 2.2-11.1-0.5-4.4-1.7-5.3 0.2-3.9 4.3 0 8.6-0.8 4.2-0.7 8.4 3.2 6.8 6.8 11.2 3.5 5.4 3.1 4.5 0.6 0.6-2.4 1-2.8 1.5-1.5 1.3-2.5-0.7-5.5 1-5.2 6.3 1.2 7.4-0.4 6.1 2 7.9 11.5 5.1 4.8 7 2 7.3 1.5-0.3 6.9-2.3 6.8 0.5 3.9 2.1 3.4 7.3 4.4 3.9 3.8 15.1 2.6 15.4-1.6 5.4-3.1 0.1 5.9 1.8 5.7 0.7 13.1 1.6 3.8 8.1-8 4.7-3.8 6.3-1 4.4-0.7 0.2 0.9 1.9 0.4 1.9-2.4 1.4 0 1.8 9.4-0.1 3.4 1.6 0 0-0.1 0.2-3.2 0.6-3.2 0.9-2.8 1.5-1.9 0-1.6-0.2-0.4-0.5-1.4 0.4-0.8 1.3 0.6 2.1 2 0.7 1.8 0.5 5 3.6 5.6 5.7 19.8-1.1 54.6 0.6 3.7 2.4 8.2 0.3 4.8-1.3 9.3-10.6 36.8-0.6 3.8-0.2 5-0.8 4.2-3.3 6.5-0.8 2.9-1.3 4-4.4 7-2.1 3.3 1.5 5.6-2.5 6.7-14.6 23.2z"
            fill="#fab49b"
            stroke="black"
            onClick={() => onDistrictClick("අම්පාර")}
          />

          <text x="690" y="680" fill="black" fontSize="20" fontWeight={900}>
            {"අම්පාර"}
          </text>
          <path
            d="M623.8 412.4l3.9-1.3 20.7 2.2 0.7 2.4 4.8 16.3 1.8 10.6-4.2 5.8-2.3-9.6-0.3-1.4-3.6-9.7-1.6 0-0.3 7.4 0.3 2.3-0.2-0.1-0.2 0.7 0 1.2 0.4 1.4 0.7 0.9 2 0.6 0.4 0.7 1.9 4.9 4 3 3.9-0.3 1.3-5 1.5 0 1.4 2.6 0.4 0.7 1.9 8.4 1.9 3.3 2.6 2.8 1.7 2.7 2.8 6.6 1.1-1.1 1.5-1.1 0.7-1.1 1 2.2-1 1.1 0.5 0.9 0.5 0.5 0 0.1 0.3 0.5 0.1 1.1 0.1-0.1 1.1-0.9 2.4-1.2 1.2-0.9 0.5 1.3 0.7 1.6-0.5 1.9-0.8 1.9 0 1.4 0.1 1.4 1.6 3.2 2.8 2.2 1.9 1.4 1.7 2.8-1.1 0.1-0.5 0.3-0.7 0.5-0.5 0.3-0.4 0.2-0.7 2.6-0.6 2.5 2.7 7.9 1.4 1.7 3.3 3.8 0.1 0 4.8-0.9 1.6 0 1.6 4.9 1.8 3 0.6 0.9 9.9 10.4 3.2 5.1 0.6 4.5-5.1 1.6-2.4-1.6-5.6-7.3-3-2.5-3.8-1.4-0.1 0-0.4 0-2.7 0.2-2.4 2.5-1.6 5.2 2.7 1.2 2.7 0.4 2.3-0.9 1.7-2.3 2.7 1 2.5 1.2 0.9 1.1 0.6 0.7-0.4 2.5 3.1 2.8 3 2.9 2.4 2.8 2.5 4.2-1.2 1.4-0.3 1.3 0.4 1.6 1.1 2.2 1.5-1.8-0.9-3.1 2.7 0.9 5.4 4 1.3 1.8 4.2 10.9-0.3 0.4-1.3 5.9 0.2 0.7 0 0.1 1.1 2.2 0.3 0.9-0.9 1.6-1.7 1.3-1 1.4 1.2 2.1 1.2 1.5 0.3 1.1 0.1 0.2 0.7 1.3 1.7 1.5-2.5 1.8 0.2 0.9-4.4 0.7-6.3 1-4.7 3.8-8.1 8-1.6-3.8-0.7-13.1-1.8-5.7-0.1-5.9-5.4 3.1-15.4 1.6-15.1-2.6-3.9-3.8-7.3-4.4-2.1-3.4-0.5-3.9 2.3-6.8 0.3-6.9-7.3-1.5-7-2-5.1-4.8-7.9-11.5-6.1-2-7.4 0.4-6.3-1.2-3.3-18 2.1-17.9 1.7-6.8 5.2-3.5 6.6-0.9 8.3 1.1 2.2-0.2-1.9-8.6-3.9-8.4-1.7-8.6 1.9-36.7-1.3-12.2z m98.8 139l-0.4-2.5-0.9-1.9-1.1-2.3-0.7-2.3 0.1-2.4 0.7-2.1 0.3-2.3-1.1-2.7 2.9 2.9 2.9 6.7 2 3.1 0.5 1.3 2.2 5.8 5.6 8.9 2.6 4.2 3.9 20.2 0 0.1-0.2 4.6-3.4 2.8-3.2-2.5-1-1.1-0.5-0.5-0.1-1.1-0.2-1.2 0-0.5 1-14.6-0.7-5-2.5-2.2-1-2.1-7.7-11.3z"
            fill="#fbc8b3"
            stroke="black"
            onClick={() => onDistrictClick("මඩකලපුව")}
          />

          <text x="610" y="520" fill="black" fontSize="20" fontWeight={900}>
            {"මඩකලපුව"}
          </text>
          <path
            d="M363.2 748.2l5.1-4.6-5.7-6 1.2-2.8 1-3.2 2.5-4 2.9-3.6 3.6-1.6 9.6 9.7 2.8 3.7 3.6 2.7 1.6-0.9 2.4-0.5 1.6 2 1.1 2.3 5.8 1.3 7-4 2.9-0.3 3.5 0.1 4.9 2.8 6.4 2.2 4.5 0.6 4.6-0.1-0.1 3.8 1.5 1.7-1.7 1.6-0.9 2.4 4.8 3.6 5.6 0.5 12 2.7 12.6 1.7 4.9-0.8 5.7-0.5 7.6 0.3 7.2-1 13.1-6.8 0.6 8.5 1.7-0.8 1.7-0.7 3.2 3.5 3.9 1.2-0.5 2.2-0.7 1.8-0.9 0.9-6.3 2.8-1.1 3.1 2.5 3.8 3.5 2.9 5.3 1 5.5-2.3 4.7-3.5 4.2-0.2-3.8 5.8-2.3 5.9-4.3 5.1-2.2 5.2-2.2 10-4.4 6.2-3.5 6.6 0.7 3.8 1.5 3.7 0.2 14.3 2.5 5 3.3 4.3 4.4 2.4 4.2 3 2.2 4.2 1.8 5.4 4.3 4.7 2.1 5.9-1.1 4.1-4.9-1.6-8.4-1.7-2.7-3.1-3.5-2.8-4.4 0.4-4.4 1.1-4.5-0.7-16.9-6.1-13.1-2.6 0-2.2 1.2-1.2-3-2.2 0.2-2.2-2.6-3.2-5.5 0.9-4.1-0.7 1.5-4.1-3.3-6.2-10.2 1.4-3.9 0-1.6 3.8-4.5 1-4.5 0.7-2-2.9-3.7 0.1-7.2-3.5-3.4-0.1-3.9-1.6-3.2-2.7-2.8-2.9-8.3-14.5-9.7-13.7-1.3-3.9 3.5-1.4-3.3-6.4-5.2-4.7-3.2-1.2-2.5-2.4-1.4-6.8-7-6.8-2.3-4.6-1.4-4.4 0.3-3.7-4-10.7-0.8-4.7z"
            fill="#c6c4d5"
            stroke="black"
            onClick={() => onDistrictClick("රත්නපුර")}
          />

          <text x="420" y="810" fill="black" fontSize="20" fontWeight={900}>
            {"රත්නපුර"}
          </text>
          <path
            d="M692.5 802.6l-4.1 4.9-6.2 1.1-4.9 3.7-4.2 4.1-5.5 2.4-4.5 3.7 0.1 3.9 1.1 3.1-1 1.7-1 1.4 0.1 1.9 0 2-3 4.9-6.4-1.4-6.1 2.2-2.1-0.3-0.5-0.6-2.6 1.8-13.7 5.3-6.7 1.8-3.5 1.4-2.3 2.3-6.3 3.5-5.7-2.4-3.6-6-4.3-5.7-2.9 0.4-2.1 1.5-2.5 0.1-2.3-0.4-1.7-4.2-3.9-1.4-5.8-0.7-5.8 0-2.1 2.5-2.9-2 0.1 3.1-1.9 2.6-3.2 0.3-2.9 0-1.1 5.2 2.2 5.2-5 3.8-6.5 2.1-5.8 3.2-5.7 3.7-1.8-5.4-2.2-4.2-4.2-3-4.4-2.4-3.3-4.3-2.5-5-0.2-14.3-1.5-3.7-0.7-3.8 3.5-6.6 4.4-6.2 2.2-10 2.2-5.2 4.3-5.1 2.3-5.9 3.8-5.8 2.8 0.2 1.7 2.7 1.1 4 1.8 1 1.9 0.4 2.1 4.7 1.6 5.1 1.6 0.8 1.6 0.9 1 7.4 2.6 3.3 2 3.3 0.9-6.2 3.3-5.1 2.5 1.6 2.5 1.2 0.7-5.5 4.8-1.7-3.4-5.4-2.2-6.4 0-3.6 0.5-3.4 3.4-5.3-0.3-4.1-4.1-1.1-0.9-3 2-4.2-0.5-5 0.6-4.9 7.8-0.7 2-3.9 6.7-2-5.6-7.9 4.9-2.7 5.9-1.6 2.9-2.4 2.3-2.9 1.1-1.9 1.4-1.4 2.3 1 3-0.6 1.1-3.4 0.6-3.6 1.6-2.4 1.2-2.6 0.9-6.4 1.1-4.2 0-4.3-0.7-3.2-1.2-3.1-1.7-2.1-1.2-1.1-2.5-3.2-8.5 2.2-2.7-2.8-2.3-4.8-3.5-3.5-2.9-3.7 0.7-4.7 2.7-4.1 3.8 0.5 3.2 3.5 3.8-1.6 2.1-3.6 6.3-8 1.8-4.4 0-4.8 9-2.1 1.8 4.4 2 3.8 4.7-1.6 4.3-3.1-1.2-8.7-0.1-8.9 5.7 0.2 4.7-2.6 1.1-1.6 1.6-1.9 1-2.3-0.2-2.8-1.3-2.7 0-3 2.3-0.2 3.2-0.8 1.6-2.5 0.8-2.2 2.6-1.1 2.8-1 5.7-3.1 4.6 1.2-3.4 5.3 1.4 0.9 1.5 0.3-0.4 3.1 2.7 12.2-0.3 5.6 0.9 4.5 5.1 4.4 3.2 5.6 0.7 5 5 7-3.7 2.9-4.8 5.7-2.7 7.2 2.9 5.5 1 3.6 1.8 3.2 1.8 0.8 1.7 0 1.5 0.8 1.2 1.1 8.2 2.1 6.8 4.1-0.5 4.4-1 4.6 1.6 4.4 0 4.5 2.7 4 1.2 4.6-6 85.1z"
            fill="#d4c9c3"
            stroke="black"
            onClick={() => onDistrictClick("මොණරාගල")}
          />

          <text x="590" y="750" fill="black" fontSize="20" fontWeight={900}>
            {"මොණරාගල"}
          </text>
          <path
            d="M363.2 710l0.7-1.9-0.1-2.1-3.4-11 1.2-6.8 2.9-6.7 3.4-2.4 1.7-3.9-4.9-1.4-5.6 1.3-2.8-5.8 1-6.6 3.6-1.9 3-2.8 0.6-8 2.3-1.8-0.4-3.1 4.7-2.7 4.8-4.3 5-3.6 5.4-2.5 11.3-2.5 2.5-0.1 1.6-2.3 0.8-2.7-0.3-4.8 0.8-4.4 5.1-2 5.5 0.7 3.4 3.5 2.1 4.5 2.8 7.1 10 8.7-0.4 4.5 2 1.4 5.8 3.3 2.8 3.4-0.5 4.1 0 3.4 3.4 3.3 2.6 3.4-1.3 3.1-1.8 2.8-2.5 0.6-2.5 0.2-3.6 3.9-8 1.3-2.6 2.4 3.5 8.4 1.6 2.9-1.6 3.6-0.2 4.6 0.3 0.7 0.8 0.2-2 3.3-3.2 2.8-1.8 0.6-1.2 1.8 1.2 1.7 1.5 1.5-1.6 8.7 2 6.7 6.7 2.1 5.3 4 1.8 6.1 0.4 3.1-2.7 2.4-4.6 0.1-4.5-0.6-6.4-2.2-4.9-2.8-3.5-0.1-2.9 0.3-7 4-5.8-1.3-1.1-2.3-1.6-2-2.4 0.5-1.6 0.9-3.6-2.7-2.8-3.7-9.6-9.7-3.6 1.6-0.1-2.3-0.3-2.2 3.1-2.9-0.7-4.2-4-2-5-0.4z"
            fill="#d7dad3"
            stroke="black"
            onClick={() => onDistrictClick("කෑගල්ල")}
          />

          <text x="370" y="660" fill="black" fontSize="20" fontWeight={900}>
            {"කෑගල්ල"}
          </text>
          <path
            d="M549.6 562l0.6 0.6 3.5 2.1 1.5 3.3 0.3 3.8 1.8 5.6 5.7 9.5 4.2-1.5 2.3-0.5 2.8-1.1 4.9-4.6 11.3-6.3 10.3 2.2 0.8 11.6-2.4 11.3-1.3 2.4-0.6 2.1 0.7 2.9 0.9 2.9 1.5 10.2 2.6 5.5 3.4 3.4 2-0.5 2.8 0.5 1 2.1 1.6 2.7 2.1 4.2 0.8 4.2-9 2.1 0 4.8-1.8 4.4-6.3 8-2.1 3.6-3.8 1.6-3.2-3.5-3.8-0.5-2.7 4.1-0.7 4.7 2.9 3.7 3.5 3.5 2.3 4.8 2.7 2.8 8.5-2.2 2.5 3.2 1.2 1.1 1.7 2.1 1.2 3.1 0.7 3.2 0 4.3-1.1 4.2-0.9 6.4-1.2 2.6-1.6 2.4-0.6 3.6-1.1 3.4-3 0.6-2.3-1-1.4 1.4-1.1 1.9-2.3 2.9-2.9 2.4-5.9 1.6-4.9 2.7 5.6 7.9-6.7 2-2 3.9-7.8 0.7-0.6 4.9 0.5 5-2 4.2 0.9 3 4.1 1.1 0.3 4.1-3.4 5.3-0.5 3.4 0 3.6 2.2 6.4 3.4 5.4-4.8 1.7-0.7 5.5-2.5-1.2-2.5-1.6-3.3 5.1-0.9 6.2-2-3.3-2.6-3.3-1-7.4-1.6-0.9-1.6-0.8-1.6-5.1-2.1-4.7-1.9-0.4-1.8-1-1.1-4-1.7-2.7-2.8-0.2-4.2 0.2-4.7 3.5-5.5 2.3-5.3-1-3.5-2.9-2.5-3.8 1.1-3.1 6.3-2.8 0.9-0.9 0.7-1.8 0.5-2.2-3.9-1.2-3.2-3.5-1.7 0.7-1.7 0.8-0.6-8.5 4.4-1.1 2.3-3.4-2.1-1-2.1-1.4 3.4-5.5-0.9-1.6-1.2-1.4-1.3-3.6-1.6-3.2-7.3-3.7-0.3-3.7 4.2-0.2 4.3 0.2 3.1-3.5 1.1-4.6 3.7-3.4 4.1-2 8.8-1.9 5.1-7.6 1-4.5 2.1-4.1 1.9-1.7 1.5-2 0.8-1.7-0.4-1.6-1.2-4.1 1.1-2.1 1.6-2-0.9-1.2-1-1.2-0.5-2 0.5-2.1-1.7-5.8 0.2-4.2 2.9-1.8 4.9-1.4 6.2-5.7 0.4-7.6-5-16.8-2-35.1-1.2-19 2.3-10.9z"
            fill="#eae4df"
            stroke="black"
            onClick={() => onDistrictClick("බදුල්ල")}
          />

          <text x="545" y="700" fill="black" fontSize="20" fontWeight={900}>
            {"බදුල්ල"}
          </text>
          <path
            d="M549.8 537.3l-0.2 3.9 1.7 5.3 0.5 4.4-2.2 11.1-2.3 10.9 1.2 19-7.3-0.8-4.7 0.7-2.6 4.3-1.4 1.3-1.7 0.9-2.8-3.5-3.8 1.9-9.5-0.1-7.3 6.1-6.6 1-8.6-7.1-3.4-1.4-3.2 2-1.4 3.6 0.8 8.5-5 5.5-8.4-0.5-14.7-9.2-4.7-6.6 0.1-2.4 1.3-1.7 0.5-2.8-0.7-2.9-1.2-2.5-1.4-2.3-2.3-4.7 0.6-4.9 3.3-3 0.8-2-0.1-2.1 0.7-2.7 0.1-2.7-4.2-4.2-1.3-7.2-0.7-7.7-1.1-1.7-0.9-0.6 0-4-1.6-4-2.3-3.8-1.6-3.7-1.8-0.5-2-1.1-0.6-4.1 1.1-3.8 4.9-3.6 7.7-2.9 4.1-4.9 3.7 0.1 2.8 1.8 3.4-2.3 1.7-2.8 1.6-1.2 1.5-0.8-0.1-1.7-0.6-1.1 3-3 2.2-3.4 0-4.3 1.2-3.8 2.4 0 1.7 0.5 1.3-1 4.5-4.7 3-2.6 4.4-2.2 4.8-2 2 2 3.2 3.6-0.1 4.1 1.3 1.3 1.9 0.8 1.5-1 0.1-1.5 2.8-1.3 2.7 0.8 3.6 0.7 3.4 2.1-6.9 7.2-2.3 11-0.6 5.4-2.2 9.4-0.8 1.6-0.4 1.7-6.7 1.5-1.6 6.4 3.1 14.1-0.4 4.6 2.3 3.2 6.1 1.3 5.8-2.4 4.5-4.2 2 2.8 3.6 2.2 1-4.1 0.5-4.1 12.3 0.8 12-2.2z"
            fill="#ebe5f1"
            stroke="black"
            onClick={() => onDistrictClick("මාතලේ")}
          />

          <text x="470" y="570" fill="black" fontSize="20" fontWeight={900}>
            {"මාතලේ"}
          </text>
          <path
            d="M534.8 393.9l3.5-1 2.7 4 3.7 1.5 3.6 2.7 0.9 4.9 2.9 2.9 9.7-1 17.5 4.3 4.1 0 3.8 2.2 3.7 2.9 4.1 2.1 2.9-0.3 1.7 2.4 0.3 2.9-0.1 3 0.8 4.4-1.2 2.9-2.7 1.7 0.9 0.7 1.2 0.4 5.7-1.4 3.3-7.6 1.2-2.2 1.4-6.1 1.6-1.4 1.8-1.3 1.5-2.8 2.1-2.3 2.9 0 3.5 0 1.3 12.2-1.9 36.7 1.7 8.6 3.9 8.4 1.9 8.6-2.2 0.2-8.3-1.1-6.6 0.9-5.2 3.5-1.7 6.8-2.1 17.9 3.3 18-1 5.2 0.7 5.5-1.3 2.5-1.5 1.5-1 2.8-0.6 2.4-4.5-0.6-5.4-3.1-11.2-3.5-6.8-6.8-8.4-3.2-4.2 0.7-8.6 0.8-4.3 0-12 2.2-12.3-0.8-0.5 4.1-1 4.1-3.6-2.2-2-2.8-4.5 4.2-5.8 2.4-6.1-1.3-2.3-3.2 0.4-4.6-3.1-14.1 1.6-6.4 6.7-1.5 0.4-1.7 0.8-1.6 2.2-9.4 0.6-5.4 2.3-11 6.9-7.2-3.4-2.1-3.6-0.7-2.7-0.8-2.8 1.3-0.1 1.5-1.5 1-1.9-0.8-1.3-1.3 0.1-4.1-3.2-3.6-2-2 0.3-6.1 4.5-6.4-4.6-3.6 0.4-8.1 1.6-9.1 3.3-8.8 1.9-9.8 3.4-8.9 9.1-1.7 10.2 0 3-1.8 0.9-4.1 1.8-4.1 2.9-3.1z"
            fill="#cdd491"
            stroke="black"
            onClick={() => onDistrictClick("පොලොන්නරුව")}
          />

          <text x="500" y="460" fill="black" fontSize="20" fontWeight={900}>
            {"පොලොන්නරුව"}
          </text>
          <path
            d="M308.3 641.1l-0.6-13.8-8.5-45.8-0.4-8 0.4-8 2.5-6.4 3.5-5.9 0.9-2.7 1.6-2.3 3.3-0.8 2.8-1.5-1.3-2.2-2.8-0.7 1.1-3 1.6-2.7 3-0.9 3.3-0.3 2.5-1 1.6-2.2-0.9-2.4-1.2-2.6 0.7-6.2 2.2-5.6 0.4-2.8 0.9-2.5 3-1.6 2.8-1.9 0.6-2.5 1.1-2.4 4.9-2.5 0.9-2.1 0.4-2.4 1.4-3.2 3.4-1.6-0.2 0.4-0.2 0.4 1.4-0.4 1.8-1.9 0.1-2.8 1.3-2.3 4.8 2.7 2.9-2.2-2.8-5.2 1.4-2.7 1.7-2.6 0.2-5.3-0.2-6.3 0.9-3 0.6-2.8-2.7-1.8-3.2-1.1-2.2-4.1-0.8-4.6-2.4-9.2 0.1-4.7-0.5-4.4 5 2.8 21.3 9.7 4.5 1.2 4.4 1.8 3.5 2.4 4.2 0.7 7.7 2.9 6.9 5.7 3.8 3.8 4.2 2.6 4.3-0.2 3.5 2.9 0 2.9-0.4 2.9 0.7 5-0.3 4.2 0.4 4.1 3.2 1.3 3.8 0.3 1.9 3.2 0.6 3.9 2.1 5.4 1.2 5.5-0.9 8.1 5.9 5.6-1.1 3.8 0.6 4.1 2 1.1 1.8 0.5 1.6 3.7 2.3 3.8 1.6 4 0 4 0.9 0.6 1.1 1.7 0.7 7.7 1.3 7.2 4.2 4.2-0.1 2.7-0.7 2.7 0.1 2.1-0.8 2-3.3 3-0.6 4.9 2.3 4.7 1.4 2.3 1.2 2.5 0.7 2.9-0.5 2.8-1.3 1.7-0.1 2.4-2.8 1.3-2.7 0.7 1.9 3 1.3 3.1-2.4 1.2-3.4-0.5-3.3 1.8 0.3 2.6-0.3 2.4-4-0.3-3.5-2.5-3.4 0-0.7 4.6-1 1.3-0.8 1.4 0.2 1.5 0.4 1.1-4.1 1.2-4.8-0.5-2.1-4.5-3.4-3.5-5.5-0.7-5.1 2-0.8 4.4 0.3 4.8-0.8 2.7-1.6 2.3-2.5 0.1-11.3 2.5-5.4 2.5-5 3.6-4.8 4.3-4.7 2.7-4-2.9-5.7-6.6-1.4-1.9-0.6-2-2.1-2.3-6.4 1.2-5.7 4-6.6 1.9-6.4-4.4-5.5 0.2-8.8 6.9-4.9 1.9z"
            fill="#fbc8b3"
            stroke="black"
            onClick={() => onDistrictClick("කුරුණෑගල")}
          />

          <text x="330" y="560" fill="black" fontSize="20" fontWeight={900}>
            {"කුරුණෑගල"}
          </text>
          <path
            d="M495.4 261.4l6.5-2.6 6.8 0.1 5.8 3.4 10.1 7 3.5 3.9 1.9 2.5 2.2 1.7-2.7 6-3.9 5.8 0.1 10 4.3 9.4 1.7 4.9-3.5 8.3 0.2 4.8 1.9 2.2 2.5 2.4 0.9 5.6 6.1 9.1 3.3 7.7 2 2.7 2.7 2 1.5 3.6-1.9 3.4-3.2 2.2-2.9 2.8-0.9 2.8-0.6 2.9-5 17.9-2.9 3.1-1.8 4.1-0.9 4.1-3 1.8-10.2 0-9.1 1.7-3.4 8.9-1.9 9.8-3.3 8.8-1.6 9.1-0.4 8.1 4.6 3.6-4.5 6.4-0.3 6.1-4.8 2-4.4 2.2-3 2.6-4.5 4.7-1.3 1-1.7-0.5-2.4 0-1.2 3.8 0 4.3-2.2 3.4-3 3 0.6 1.1 0.1 1.7-1.5 0.8-1.6 1.2-1.7 2.8-3.4 2.3-2.8-1.8-3.7-0.1-4.1 4.9-7.7 2.9-4.9 3.6-5.9-5.6 0.9-8.1-1.2-5.5-2.1-5.4-0.6-3.9-1.9-3.2-3.8-0.3-3.2-1.3-0.4-4.1 0.3-4.2-0.7-5 0.4-2.9 0-2.9-3.5-2.9-4.3 0.2-4.2-2.6-3.8-3.8-6.9-5.7-7.7-2.9-4.2-0.7-3.5-2.4-4.4-1.8-4.5-1.2-21.3-9.7-5-2.8-2.5-3.3-3.4-2.8-4.7 0.2-4.1-3.1-3.9 1.8-4.8 0-1.4-4.2-5.8-23.4-3.4-6.6 2.6-5.7 3.9-1.8 1-2.1 0.3-1.7 7.2-5.9 4.2-8.9-1.3-9.6 1.9 0.9 2.2 0.3 0.5-21.6-1.8-13.7 10.2 4.2 11.3 0.1 22.5-5.9 0.1 7.5 2.3 6.6 3.5 1.2 3.4 1.6 1.5 2.8 0.9 3.2 2.4 4.9 4.9 2.6 2.4 0.7 2.2 0.1 3.5-6.3 4.1-1.8 4.3-10.1 9.1-11.8 7-3.2 4.3 2.2 3.9 2.8 7.3 0 6.2-4.7 5.8-5.3 5-5.9 2.8-4.3 3.3-3.9 5.5-4.4-2-6-4.2-5.5-6-2.1-0.4-5.1 17.1-0.6 6.7-4.7 6.1-1.8 6.3-1.2z"
            fill="#eeedaa"
            stroke="black"
            onClick={() => onDistrictClick("අනුරාධපුර")}
          />

          <text x="400" y="400" fill="black" fontSize="20" fontWeight={900}>
            {"අනුරාධපුර"}
          </text>
          <path
            d="M508.4 753.2l-13.1 6.8-7.2 1-7.6-0.3-5.7 0.5-4.9 0.8-12.6-1.7-12-2.7-5.6-0.5-4.8-3.6 0.9-2.4 1.7-1.6-1.5-1.7 0.1-3.8 2.7-2.4-0.4-3.1-1.8-6.1-5.3-4-6.7-2.1-2-6.7 1.6-8.7-1.5-1.5-1.2-1.7 1.2-1.8 1.8-0.6 3.2-2.8 2-3.3-0.8-0.2-0.3-0.7 6.6 0.6 10.4 15.4 6.7 4 5-5.3 2.7-7.8-2.7-3.7-3.4-3.3-4.1-7.5-1.8-0.8-1.9 0.6 5.2-4.2 8.4 1 4.6-1 6.7-4.6 4.5-0.1 4 1 14.8-17.6 4.7-7.9-2.1-8.7-0.7-6.7 4 3.6 3.3 4.4 2.4 0.9 2 0.2 5.3 8.4 8.4 0.6 9.9-1.4 9.6 1.9-0.2 4.2 1.7 5.8-0.5 2.1 0.5 2 1 1.2 0.9 1.2-1.6 2-1.1 2.1 1.2 4.1 0.4 1.6-0.8 1.7-1.5 2-1.9 1.7-2.1 4.1-1 4.5-5.1 7.6-8.8 1.9-4.1 2-3.7 3.4-1.1 4.6-3.1 3.5-4.3-0.2-4.2 0.2 0.3 3.7 7.3 3.7 1.6 3.2 1.3 3.6 1.2 1.4 0.9 1.6-3.4 5.5 2.1 1.4 2.1 1-2.3 3.4-4.4 1.1z"
            fill="#d8d4df"
            stroke="black"
            onClick={() => onDistrictClick("නුවරඑළිය")}
          />

          <text x="430" y="730" fill="black" fontSize="20" fontWeight={900}>
            {"නුවරඑළිය"}
          </text>
          <path
            d="M498.3 254l-1.9 3.4-1 4-6.3 1.2-6.1 1.8-6.7 4.7-17.1 0.6 0.4 5.1 6 2.1 4.2 5.5 2 6-5.5 4.4-3.3 3.9-2.8 4.3-5 5.9-5.8 5.3-6.2 4.7-7.3 0-3.9-2.8-4.3-2.2-7 3.2-9.1 11.8-4.3 10.1-4.1 1.8-3.5 6.3-2.2-0.1-2.4-0.7-4.9-2.6-2.4-4.9-0.9-3.2-1.5-2.8-3.4-1.6-3.5-1.2-2.3-6.6-0.1-7.5-8.8-11.3-5.4-4.2 0-3 0.9-3.5 5.9-0.6 3.5-7.8 6.4-3.6 8.4 0.9 13-1.6 12.3-4.6 1.2-8.1-3.5-9-3.6-5.7-6.2-2.9 10.7-6.8 6.4-5.2 4.1-6.3-0.2-3.9-0.9-4 0-2.1-0.2-1.9-3.4-0.5-1.8-2.5 0.6-3.4 4.2-0.4 4.4 0.3 8-0.2 5.7 3.6 3.6 5.3 5.6-2.5 4-3.5 2.3-1.1 2.2-1.3 0.4-1.2 0.5-1.1 7.5-0.2 7.2 2.7 0.8 7.5 6.2 0.7 2.7 4.7-2.2 3.3-0.4 3.2 3.6-0.9 3.4-2.3 7.2 1.1 7.4 4.5 2.8 3.7-4.3 15.8z"
            fill="#abd9b5"
            stroke="black"
            onClick={() => onDistrictClick("වවුනියාව")}
          />

          <text x="385" y="300" fill="black" fontSize="20" fontWeight={900}>
            {"වවුනියාව"}
          </text>
          <path
            d="M428.6 698.3l0.2-4.6 1.6-3.6-1.6-2.9-3.5-8.4 2.6-2.4 8-1.3 3.6-3.9 2.5-0.2 2.5-0.6 1.8-2.8 1.3-3.1-2.6-3.4-3.4-3.3 0-3.4 0.5-4.1-2.8-3.4-5.8-3.3-2-1.4 0.4-4.5-10-8.7-2.8-7.1 4.8 0.5 4.1-1.2-0.4-1.1-0.2-1.5 0.8-1.4 1-1.3 0.7-4.6 3.4 0 3.5 2.5 4 0.3 0.3-2.4-0.3-2.6 3.3-1.8 3.4 0.5 2.4-1.2-1.3-3.1-1.9-3 2.7-0.7 2.8-1.3 4.7 6.6 14.7 9.2 8.4 0.5 5-5.5-0.8-8.5 1.4-3.6 3.2-2 3.4 1.4 8.6 7.1 6.6-1 7.3-6.1 9.5 0.1 3.8-1.9 2.8 3.5 1.7-0.9 1.4-1.3 2.6-4.3 4.7-0.7 7.3 0.8 2 35.1 5 16.8-0.4 7.6-6.2 5.7-4.9 1.4-2.9 1.8-9.6-1.9-9.9 1.4-8.4-0.6-5.3-8.4-2-0.2-2.4-0.9-3.3-4.4-4-3.6 0.7 6.7 2.1 8.7-4.7 7.9-14.8 17.6-4-1-4.5 0.1-6.7 4.6-4.6 1-8.4-1-5.2 4.2 1.9-0.6 1.8 0.8 4.1 7.5 3.4 3.3 2.7 3.7-2.7 7.8-5 5.3-6.7-4-10.4-15.4-6.6-0.6z"
            fill="#d9cde5"
            stroke="black"
          />

          <text x="460" y="635" fill="black" fontSize="20" fontWeight={900}>
            {"මහනුවර"}
          </text>
        </g>
      </svg>
    </div>
  );
}

export default MySVGComponent;
